import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
// import parseMarkdown from '../../utils/markdownParser';
import { GET_NEXT_SECTION, GET_PREV_SECTION } from '../../queries';
// import Loading from '../modules/Loading';
import { Caption } from '../modules/Typography';
import Icon from '../modules/Icon';

const BottomNav = props => {
    const [prevSection, setPrevSection] = useState(props.id - 1);
    const [nextSection, setNextSection] = useState(~~props.id + 1);
    const { data: next } = useQuery(GET_NEXT_SECTION, { variables: { ID: nextSection } });
    const { data: prev } = useQuery(GET_PREV_SECTION, { variables: { ID: prevSection } });

    const location = useLocation();
    const paths = location.pathname.split('/');

    return (
        <>
            <hr className='padded' />
            <ul className='BottomNav__buttons flex center'>
                {prev && prev.sections.data.length > 0 &&
                <div className='BottomNav__prev flex'>
                    <Link to={`${paths[0]}/${paths[1]}/${prev.sections.data[0].attributes.slug}`}>
                        <div className='BottomNav__right inline-block'>
                            <Caption className='BottomNav__title'>Production Guide</Caption>
                            <li className='BottomNav__section'>{prev.sections.data.length ? prev.sections.data[0].attributes.title : ''}</li>
                        </div>
                        <Icon className='BottomNav__icon' size={'isMobile ? \'medium\' : \'large\''} name='caret-left' />
                    </Link>
                </div>}
                {next && next.sections.data.length > 0 &&
                <div className='BottomNav__next flex'>
                    <Link to={`${paths[0]}/${paths[1]}/${next.sections.data[0].attributes.slug}`}>
                        <Icon className='BottomNav__icon' size={'isMobile ? \'medium\' : \'large\''} name='caret-right' />
                        <div className='inline-block'>
                            <Caption className='BottomNav__title'>Production Guide</Caption>
                            <li className='BottomNav__section'>{next.sections.data.length ? next.sections.data[0].attributes.title : ''}</li>
                        </div>
                    </Link>
                </div>}

            </ul>
            <hr className='padded' />

        </>
    );
};

BottomNav.propTypes = {
    id: PropTypes.number

};

export default BottomNav;
