import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { HashLink as Link } from 'react-router-hash-link';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { GET_NAV } from '../../queries';
import IconButton from '../modules/IconButton';
import { Subtitle, Body } from '../modules/Typography';

const MobileMenu = ({ isOpen, setOpen }) => {
    const location = useLocation();
    const { data } = useQuery(GET_NAV);
    const [activeItem, setActiveItem] = useState('');

    const sortedSections = data ? [...data.sideNav.data.attributes.sectionContainer.sections.data].sort(function (a, b) {
        return a.attributes.nav_order - b.attributes.nav_order;
    }) : [];

    useEffect(() => {
        if (location.pathname.length > 1) {
            const paths = location.pathname.split('/');
            const path = paths[2];
            setActiveItem(path);
        }
        setOpen(false);
        document.body.classList.remove('no-scroll');
    }, [location.pathname]);

    return (
        <div className='NavContainer'>
            <IconButton
                type='light' name={isOpen ? 'close' : 'density-comfy'} onClick={() => setOpen(!isOpen)}
            />
            {isOpen &&
            <div className='NavBlock'>
                <ul className='NavMenu'>
                    <li className='NavMenu__item'>
                        <Subtitle number={2}>Production Guidebook</Subtitle>
                        {sortedSections.map((item, id) => (
                            <div className={`SideNav__items ${(item.attributes.slug === activeItem) ? 'mobile active' : 'mobile'}`} key={id}>
                                <Link to={`/production-guide/${item.attributes.slug}`}>
                                    <Body number={2}>{item.attributes.title}</Body>
                                </Link>

                            </div>
                        ))}
                    </li>
                </ul>
            </div>}
        </div>
    );
};

MobileMenu.propTypes = {
    isOpen: PropTypes.bool,
    setOpen: PropTypes.func,
    setOpenSearch: PropTypes.func

};

export default MobileMenu;
