import React from 'react';
import UserContextProvider from '../contexts/UserContextProvider';
import WidthContextProvider from '../contexts/WidthContextProvider';
// import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import '../styles/main.scss';
// import GraphiqlEditor from './GraphiqlEditor';
import Home from './pages/Home';
import ProductionGuide from './pages/ProductionGuide';
import Layout from './layout/Layout';

// const routes = [
//     { path: '/', component: Home },
//     { path: '/production-guide/introduction', component: ProductionGuide },
//     { path: '/production-guide/:slug', component: ProductionGuide }
// ];

// <Redirect push from='/production-guide/diversity-and-inclusion' to='/production-guide/representation-and-inclusion' />
// <Redirect push from='/production-guide/diversity-and-inclusion#representation-data ' to='/production-guide/representation-and-inclusion#studio-representation-data' />

function App () {
    return (
        <BrowserRouter>
            <UserContextProvider>
                <WidthContextProvider>
                    <Layout>
                        <Routes>
                            <Route path='/' element={<Home />} />
                            <Route path='/production-guide/introduction' element={<ProductionGuide />} />
                            <Route path='/production-guide/:slug' element={<ProductionGuide />} />
                            <Route path='*' element={<Home />} />
                        </Routes>
                    </Layout>
                </WidthContextProvider>
            </UserContextProvider>
        </BrowserRouter>
    );
}

export default App;
