import { gql } from '@apollo/client';

export const GET_NAV = gql`
query sideNav { 
  sideNav {
    data { 
      attributes {
        sectionContainer { 
          sections { 
            data { 
              attributes {
                slug 
                title 
                nav_order 
                subsections {
                  data { 
                    attributes {
                      title 
                      slug 
                      nav_order
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const GET_PREV_SECTION = gql`
query prevSections ($ID: ID!) {
  sections (filters: { id: {eq: $ID} }) {
    data { 
      attributes {
        title 
        slug
      }
    }
  }
}
`;

export const GET_NEXT_SECTION = gql`
query nextSections ($ID: ID!) {
  sections (filters: { id: {eq: $ID} }) {
    data { 
      attributes {
        title 
        slug
      }
    }
  }
}
`;
