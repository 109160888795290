import { gql } from '@apollo/client';

export const GET_HOME = gql`
  query homePage {
    homePage { 
      data { 
        attributes {
          header 
          headerImage {
            data { 
              attributes {
                url
              }
            }
          } 
          video {
            data { 
              attributes {
                url
              }
            }
          } 
          infoModule {
            button 
            title 
            description 
            section {
              data { 
                attributes {
                  slug
                }
              }
            }
          }
        }
      }
    }
  }
`;
