import React from 'react';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet-async';
import { GET_HOME } from '../../queries';
import { Heading, Body } from '../modules/Typography';
import Button from '../modules/Button';

export const HomeContext = React.createContext();

const Home = props => {
    const { data, loading, error } = useQuery(GET_HOME);
    console.log({ data, loading, error });
    const { infoModule, video, headerImage } = data ? data.homePage.data.attributes : [];

    if (loading) return '';
    return (
        <HomeContext.Provider value={{ }}>
            <div>
                <Helmet><title>Production Guidebook</title></Helmet>
                <header className='Home__header'>

                    <img src={headerImage ? headerImage.data.attributes.url : '#'} />
                    <Heading className='Home__header__text'>Welcome to the&nbsp;Walt&nbsp;Disney&nbsp;Studios Production Guide</Heading>
                </header>
                <div className='Home__BoxSection'>
                    {infoModule && infoModule.map((box, id) => (
                        <div className='Home__BoxSection__box' key={id}>
                            <Heading className='Home__BoxSection__title' number={3}>{box.title}</Heading>
                            <Body className='Home__BoxSection__text' block number={1}>{box.description}</Body>
                            <Link to={`/production-guide/${box.section.data.attributes.slug}`}>
                                <Button className='Home__BoxSection__button'>{box.button}</Button>
                            </Link>
                        </div>
                    ))}
                </div>
                <ReactPlayer width='100%' height='auto' playing controls muted loop url={video.data.attributes.url} />

            </div>
        </HomeContext.Provider>
    );
};

Home.propTypes = {

};

export default Home;
