import { gql } from '@apollo/client';

export const GET_SECTION = gql`
query sections ($slug: String!) {
  sections (filters: { slug: {eq: $slug} }) { 
    data { 
      id 
      attributes {
        title 
        slug 
        content {
          ...on ComponentTipTip {
            tip 
            __typename
          } 
          ... on ComponentTipKeyAction {
            keyAction 
            __typename
          } 
          ... on ComponentTipNote {
            note 
            __typename
          } 
          ... on ComponentTipLink {
            externalLink {
              link 
              title
            }
          } 
          ...on ComponentDescriptionDescription {
            description 
            __typename
          }
          ... on ComponentLayoutDynamicImage {
            link
            text
            position
            imagewidth
            pdf {
              data { 
                attributes { 
                  url
                }
              }
            }
            reqimage: image {
              data { 
                attributes { 
                  url
                }
              }
            } 
            __typename
          }
        } 
        subsections {
          data { 
            attributes { 
              title 
              nav_order 
              slug 
              details { 
                ... on ComponentLayoutLightbox {
                  title 
                  caption 
                  image {
                    data { 
                      attributes { 
                        url
                      }
                    }
                  } 
                  __typename
                }
                ... on ComponentLayoutDynamicImage {
                  link
                  text
                  position
                  imagewidth
                  pdf {
                    data { 
                      attributes { 
                        url
                      }
                    }
                  }
                  reqimage: image {
                    data { 
                      attributes { 
                        url
                      }
                    }
                  } 
                  __typename
                }
                ... on ComponentTipTip {
                  tip 
                  __typename
                } 
                ... on ComponentTipKeyAction {
                  keyAction 
                  __typename
                } 
                ... on ComponentTipNote {
                  note 
                  __typename
                } 
                ... on ComponentResourceLinkResourceLink {
                  link 
                  title 
                  __typename
                } 
                ... on ComponentDescriptionDescription {
                  description 
                  __typename 
                }
              }
            }
          }
        }
      }
    }
  }
}
`;
