import React, { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { WidthContext } from '../../contexts/WidthContextProvider';
import { Subtitle } from '../modules/Typography';
import logo from '../../images/wds_logo.svg';
import logoWhite from '../../images/logo_white.svg';
import MobileMenu from './MobileMenu';

const TopNav = props => {
    const location = useLocation();
    const [isOpen, setOpen] = useState(false);
    const { isMobile } = useContext(WidthContext);
    const guideBook = location.pathname.includes('production-guide');

    if (isOpen) {
        document.body.classList.add('no-scroll');
    } else {
        document.body.classList.remove('no-scroll');
    }

    return (
        <div className={`TopNav ${guideBook ? 'invert' : ''}`}>
            <nav className='TopNav__container flex'>
                <Link to='/'>
                    <img src={guideBook ? logoWhite : logo} width='208px' />
                </Link>
                <ul>
                    <li className='TopNav__MenuItem'>
                        {isMobile
                            ? <MobileMenu isOpen={isOpen} setOpen={setOpen} /> : <Link to='/production-guide/introduction'><Subtitle number={2}>Production Guide</Subtitle></Link>}
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default TopNav;
