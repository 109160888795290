import React from 'react';
import PropTypes from 'prop-types';
import { LightgalleryItem, LightgalleryProvider } from 'react-lightgallery';
import 'lightgallery.js/dist/css/lightgallery.css';
import parseMarkdown from '../../utils/markdownParser';
import { Link } from 'react-router-dom';
import { Caption, Heading } from '../modules/Typography';

const Subsection = props => {
    const sortedSections = props.data ? [...props.data].sort(function (a, b) {
        return a.attributes.nav_order - b.attributes.nav_order;
    }) : [];

    return (
        <>
            {sortedSections.map((content, id) => (
                <div className='Section__subsection' key={id}>
                    <hr className='Section__break' />
                    <Heading id={content.attributes.slug} className='Section__title' number={4}>{content.attributes.title}</Heading>
                    {content.attributes.details.map((detail, index) => (
                        <>
                            {detail.__typename === 'ComponentDescriptionDescription' ? (
                                <div className='Section__content' key={index}>
                                    {parseMarkdown(`${detail.description}`)}
                                </div>) : ('')}
                            {detail.__typename === 'ComponentTipKeyAction' ? (
                                <div className='Section__keyaction' key={index}>
                                    <strong>KEY ACTION</strong><br /><br />
                                    {parseMarkdown(`${detail.keyAction}`)}
                                </div>) : ('')}
                            {detail.__typename === 'ComponentTipNote' ? (
                                <div className='Section__note' key={index}>
                                    {detail.note}
                                </div>) : ('')}
                            {detail.__typename === 'ComponentLayoutLightbox' ? (
                                <div className='Section__lightbox' key={index}>
                                    <Heading className='Section__lightbox__title' number={5}>{detail.title ? detail.title : ''}</Heading>
                                    <LightgalleryProvider>
                                        <LightgalleryItem src={detail.image.data.attributes.url}>
                                            <img className='cursor-pointer' src={detail.image.data.attributes.url} />
                                        </LightgalleryItem>
                                        <Caption className='Section__lightbox__caption'>{detail.caption}</Caption>
                                    </LightgalleryProvider>
                                </div>) : ('')}
                            {detail.__typename === 'ComponentLayoutDynamicImage' ? (
                                <>
                                    {detail.position === 'left' ? (
                                        <>
                                            <div className={'Section__imageclear'}></div>
                                            <div className={'Section__imageboxleft'}>
                                                {detail.link ? (
                                                    <Link target='_blank' to={`${detail.link}`}>
                                                        <img src={detail.reqimage.data.attributes.url} className={'Section__image' + detail.imagewidth} />
                                                    </Link>
                                                ) : ('')}

                                                {!(detail.link) && (detail.pdf.data && detail.pdf.data.attributes.url) ? (
                                                    <Link target='_blank' to={`${detail.pdf.data.attributes.url}`}>
                                                        <img src={detail.reqimage.data.attributes.url} className={'Section__image' + detail.imagewidth} />
                                                    </Link>
                                                ) : ('')}

                                                {!(detail.link) && !(detail.pdf.data && detail.pdf.data.attributes.url) ? (
                                                    <img src={detail.reqimage.data.attributes.url} className={'Section__image' + detail.imagewidth} />
                                                ) : ('')}
                                            </div>
                                            <div className={'Section__imageboxright'}>
                                                {parseMarkdown(`${detail.text}`)}
                                            </div>
                                            <div className={'Section__imageclear'}></div>
                                        </>
                                    ): ('')}
                                    {detail.position === 'right' ? (
                                        <>
                                            <div className={'Section__imageclear'}></div>
                                            <div className={'Section__imageboxleft'}>
                                                {parseMarkdown(`${detail.text}`)}
                                            </div>
                                            <div className={'Section__imageboxright'}>
                                                {detail.link ? (
                                                    <Link target='_blank' to={`${detail.link}`}>
                                                        <img src={detail.reqimage.data.attributes.url} className={'Section__image' + detail.imagewidth} />
                                                    </Link>
                                                ) : ('')}

                                                {!(detail.link) && (detail.pdf.data && detail.pdf.data.attributes.url) ? (
                                                    <Link target='_blank' to={`${detail.pdf.data.attributes.url}`}>
                                                        <img src={detail.reqimage.data.attributes.url} className={'Section__image' + detail.imagewidth} />
                                                    </Link>
                                                ) : ('')}

                                                {!(detail.link) && !(detail.pdf.data && detail.pdf.data.attributes.url) ? (
                                                    <img src={detail.reqimage.data.attributes.url} className={'Section__image' + detail.imagewidth} />
                                                ) : ('')}
                                            </div>
                                            <div className={'Section__imageclear'}></div>
                                        </>
                                    ): ('')}
                                    {detail.position === 'full' ? (
                                        <>
                                            <div className={'Section__imageclear'}></div>
                                            <div className={'Section__imageboxfull'}>
                                                {detail.link ? (
                                                    <Link target='_blank' to={`${detail.link}`}>
                                                        <img src={detail.reqimage.data.attributes.url} className={'Section__image' + detail.imagewidth} />
                                                    </Link>
                                                ) : ('')}

                                                {!(detail.link) && (detail.pdf.data && detail.pdf.data.attributes.url) ? (
                                                    <Link target='_blank' to={`${detail.pdf.data.attributes.url}`}>
                                                        <img src={detail.reqimage.data.attributes.url} className={'Section__image' + detail.imagewidth} />
                                                    </Link>
                                                ) : ('')}

                                                {!(detail.link) && !(detail.pdf.data && detail.pdf.data.attributes.url) ? (
                                                    <img src={detail.reqimage.data.attributes.url} className={'Section__image' + detail.imagewidth} />
                                                ) : ('')}
                                            </div>
                                            <div className={'Section__imageclear'}></div>
                                        </>
                                    ): ('')}
                                </>
                            ) : ('')}
                            {detail.__typename === 'ComponentTipLink' ? (
                                <React.Fragment key={index}>
                                    <Heading className='Section__resource__title' number={6}>RESOURCE LINKS</Heading>
                                    {detail.externalLink.map((item, index2) => (
                                        <Link target='_blank' to={`${item.link}`} key={index2}>{item.title}</Link>
                                    ))}
                                </React.Fragment>) : ('')}
                            {detail.__typename === 'ComponentTipTip' ? (
                                <React.Fragment key={index}>
                                    <div className='Section__tips'>
                                        {detail.tip}
                                    </div>
                                </React.Fragment>) : ('')}
                        </>
                    ))}
                </div>
            ))}
        </>
    );
};

Subsection.propTypes = {
    data: PropTypes.array

};

export default Subsection;
