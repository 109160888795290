import React from 'react';
import Body from '../modules/Typography';

const Footer = props => {
    return (
        <div className='Footer flex center'>
            <Body className='Footer__copyright block center' number={2}>©Disney 2022 All Rights Reserved.</Body>
        </div>
    );
};

export default Footer;
