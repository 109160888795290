import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { ME } from '../queries';
import { useQuery } from '@apollo/client';
import Login from '../components/pages/Login';

export const UserContext = React.createContext();

const UserComponent = ({ children }) => {
    const [user, setUser] = useState({
        id: '',
        email: '',
        role: { name: '' }
    });
    const location = useLocation();
    const { data } = useQuery(ME, { errorPolicy: 'all' });

    const me = data ? data.user : {};

    useEffect(() => {
        if (me && me.id) {
            setUser({ ...me, vendors: [], teams: [] });
        }
    }, [me]);
    let content = <Login />;
    if (user.id || location.pathname === '/reset-password') {
        content = children;
    }
    return (
        <UserContext.Provider value={{
            user,
            setUser
        }}
        >
            {content}
        </UserContext.Provider>
    );
};

UserComponent.propTypes = {
    children: PropTypes.object
};

export default UserComponent;
