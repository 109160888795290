// import React from 'react';
import PropTypes from 'prop-types';
// import { Heading, Body } from '../modules/Typography';

const LoginMessageBody = (props) => {
    return (props.authorizedUser);
    // ? <div className='Login__message-body__default' />
    // : <div className='Login__message-body__nonAuthorized'><img className='Login__secure-lock' /><Heading className='Login__message-body__title' number={4} block padded>Sorry, you’re not authorized to access this website</Heading><Body className='Login__message-body__sub-title' number={1} block padded>Please reach out to your Disney contact to request access.</Body></div>);
};

LoginMessageBody.propTypes = {
    authorizedUser: PropTypes.bool
};

export default LoginMessageBody;
