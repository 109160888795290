import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import parseMarkdown from '../../utils/markdownParser';
// import { useNavigate } from 'react-router-dom';
import { GET_SECTION } from '../../queries';
import Loading from '../modules/Loading';
import { Link } from 'react-router-dom';
import { Heading } from '../modules/Typography';
import Subsection from './Subsection';
import BottomNav from '../layout/BottomNav';

const Section = props => {
    const { data, loading } = useQuery(GET_SECTION, { variables: { slug: props.slug } });
    const { sections } = data || [];
    // const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [props.slug]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {location.reload();}, 540000);
        return () => clearTimeout(timeoutId);
    }, []);

    if (loading) return <Loading />;

    // console.log(sections)

    return (
        <>
            <main className='Section'>
                <Heading className='Section__title' number={4}>{sections.data[0].attributes.title}</Heading>
                <div className='Section__body'>
                    {data.sections.data[0].attributes.content.map((content, index) => (
                        <React.Fragment key={index}>
                            <div>
                                {content.__typename === 'ComponentDescriptionDescription' ? parseMarkdown(`${content.description}`) : ''}
                            </div>
                            {content.__typename === 'ComponentTipKeyAction' ? (
                                <div className='Section__keyaction'>
                                    <strong>KEY ACTION</strong><br /><br />
                                    {parseMarkdown(`${content.keyAction}`)}
                                </div>) : ('')}
                            {content.__typename === 'ComponentTipNote' ? (
                                <div className='Section__note'>
                                    {content.note}
                                </div>) : ('')}
                            {content.__typename === 'ComponentLayoutDynamicImage' ? (
                                    <>
                                        {content.position === 'left' ? (
                                            <>
                                                <div className={'Section__imageclear'}></div>
                                                <div className={'Section__imageboxleft'}>
                                                    {content.link ? (
                                                        <Link target='_blank' to={`${content.link}`}>
                                                            <img src={content.reqimage.data.attributes.url} className={'Section__image' + content.imagewidth} />
                                                        </Link>
                                                    ) : ('')}

                                                    {!(content.link) && (content.pdf.data && content.pdf.data.attributes.url) ? (
                                                        <Link target='_blank' to={`${content.pdf.data.attributes.url}`}>
                                                            <img src={content.reqimage.data.attributes.url} className={'Section__image' + content.imagewidth} />
                                                        </Link>
                                                    ) : ('')}

                                                    {!(content.link) && !(content.pdf.data && content.pdf.data.attributes.url) ? (
                                                        <img src={content.reqimage.data.attributes.url} className={'Section__image' + content.imagewidth} />
                                                    ) : ('')}
                                                </div>
                                                <div className={'Section__imageboxright'}>
                                                    {parseMarkdown(`${content.text}`)}
                                                </div>
                                                <div className={'Section__imageclear'}></div>
                                            </>
                                        ): ('')}
                                        {content.position === 'right' ? (
                                            <>
                                                <div className={'Section__imageclear'}></div>
                                                <div className={'Section__imageboxleft'}>
                                                    {parseMarkdown(`${content.text}`)}
                                                </div>
                                                <div className={'Section__imageboxright'}>
                                                    {content.link ? (
                                                        <Link target='_blank' to={`${content.link}`}>
                                                            <img src={content.reqimage.data.attributes.url} className={'Section__image' + content.imagewidth} />
                                                        </Link>
                                                    ) : ('')}

                                                    {!(content.link) && (content.pdf.data && content.pdf.data.attributes.url) ? (
                                                        <Link target='_blank' to={`${content.pdf.data.attributes.url}`}>
                                                            <img src={content.reqimage.data.attributes.url} className={'Section__image' + content.imagewidth} />
                                                        </Link>
                                                    ) : ('')}

                                                    {!(content.link) && !(content.pdf.data && content.pdf.data.attributes.url) ? (
                                                        <img src={content.reqimage.data.attributes.url} className={'Section__image' + content.imagewidth} />
                                                    ) : ('')}
                                                </div>
                                                <div className={'Section__imageclear'}></div>
                                            </>
                                        ): ('')}
                                        {content.position === 'full' ? (
                                            <>
                                                <div className={'Section__imageclear'}></div>
                                                <div className={'Section__imageboxfull'}>
                                                    {content.link ? (
                                                        <Link target='_blank' to={`${content.link}`}>
                                                            <img src={content.reqimage.data.attributes.url} className={'Section__image' + content.imagewidth} />
                                                        </Link>
                                                    ) : ('')}

                                                    {!(content.link) && (content.pdf.data && content.pdf.data.attributes.url) ? (
                                                        <Link target='_blank' to={`${content.pdf.data.attributes.url}`}>
                                                            <img src={content.reqimage.data.attributes.url} className={'Section__image' + content.imagewidth} />
                                                        </Link>
                                                    ) : ('')}

                                                    {!(content.link) && !(content.pdf.data && content.pdf.data.attributes.url) ? (
                                                        <img src={content.reqimage.data.attributes.url} className={'Section__image' + content.imagewidth} />
                                                    ) : ('')}
                                                </div>
                                                <div className={'Section__imageclear'}></div>
                                            </>
                                        ): ('')}
                                    </>
                                ) : ('')}
                            {content.__typename === 'ComponentTipLink' ? (
                                <>
                                    <div className='Section__resource__title'>RESOURCE LINKS</div>
                                    {content.externalLink.map((item, index2) => (
                                        <div className='Section__resource' key={index2}>
                                            <a target='_blank' href={`${item.link}`} rel='noreferrer'>{item.title}</a>
                                        </div>
                                    ))}
                                </>) : ('')}
                            {content.__typename === 'ComponentTipTip' ? (
                                <>
                                    <div className='Section__tips'>
                                        {content.tip}
                                    </div>
                                </>) : ('')}
                        </React.Fragment>
                    ))}
                    <Subsection data={data.sections.data[0].attributes.subsections.data} />
                    <BottomNav id={data.sections.data[0].id} />
                </div>
            </main>
        </>
    );
};

Section.propTypes = {
    slug: PropTypes.string

};

export default Section;
