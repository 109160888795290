import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Row, Col } from '../modules/Grid';
import logoImage from '../../images/logo_white.svg';
import { Caption } from '../modules/Typography';
import LoginMessageBody from '../login/LoginMessageBody';
import LoginAdmin from '../login/LoginAdmin';
import bg from '../../images/prodguidebookbg.png';
import LoginVendor from '../login/LoginVendor';
// import LoginValidateCode from '../login/LoginValidateCode';
// import LoginPasswordSuccess from '../login/LoginPasswordSuccess';
// import LoginPasswordReset from '../login/LoginPasswordReset';

export const LoginContext = React.createContext();

const Login = () => {
    // const handleForgotPassword = async (e) => {
    //     try {
    //         e.preventDefault();
    //         // TO DO: logic to handleForgotPassword
    //     } catch (err) {
    //         setError('Someting went wrong. Please try again later.');
    //     }
    // };

    return (
        <LoginContext.Provider value={{}}>
            <div className='Login' style={{ backgroundImage: `url("${bg}")`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                <Helmet><title>Production Guidebook Login Page</title></Helmet>
                <Row className='no-gutters'>
                    <Col sm={12} lg={5} className='Login__sidebar-container flex column'>
                        <div style={{ width: '100%' }}>
                            <div className='Login__sidebar'>
                                <img className='Login__logo' src={logoImage} alt='logo' />

                                <LoginAdmin />
                            </div>
                            <div className='Login__authdivider'>
                                <span className='Login__line__left' />
                                <span>
                                    or
                                </span>
                                <span className='Login__line__right' />
                            </div>
                            <div className='Login__sidebar'>
                                <LoginVendor />
                            </div>
                        </div>
                        <div className='Login__footer'>
                            <Caption className='Login__copyright flex center' number={2}>© Disney 2022. All Rights Reserved.</Caption>
                        </div>
                    </Col>
                    <Col sm={12} lg={8} className='Login__message-body'>
                        <LoginMessageBody authorizedUser />
                    </Col>
                </Row>
            </div>
        </LoginContext.Provider>
    );
};

export default Login;
